<template>
  <CForm ref="form" class="needs-validation"   >
    <CInput
        label="Fatura No: "
        horizontal
        type="text"
        v-model="data.invoiceNo"
    />
  </CForm>
</template>

<script>
export default{
  name: "UpdateInvoiceForm",
  props:{
    validated: Boolean,
    params: Object,
    actionType: String,
  },
  watch:{
    params: function(val){
      this.data = {...val};
    },
    actionType:  function(val){
      if(val == 'create'){
        this.data = {...this.params};
      }
    },
  },
  data: function(){
    return{
      data: this.params ? {...this.params} : {},

    }
  }
}
</script>